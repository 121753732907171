import React, { useEffect, useState } from 'react';

import useCountriesData from "../../hooks/hook.useCountriesData";
import useFetchPost from "../../hooks/hook.useFetchPost";
import Button from '../component.button';
import addToMailchimp from "gatsby-plugin-mailchimp";

const BuildFleetForm = ({ loadLocation }) => {
    const countries = useCountriesData();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const defaultContactDetails = {
        country: '',
        phoneNumber: '',
        contactName: '',
        club: '',
        email: '',
        message: '',
        newsletter: false,
        onlinemeeting: false,
    }
    const [contactDetails, setContactDetails] = useState(defaultContactDetails);
    const [selectedCountry, setSelectedCountry] = useState(loadLocation);

    const handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        // handle checkbox
        if (name === 'newsletter') {
            value = target.checked;
        }

        // handle checkbox
        if (name === 'onlinemeeting') {
            value = target.checked;
        }

        // handle select
        if (name === 'country') {
            setSelectedCountry(value);
        }

        setContactDetails({
            ...contactDetails,
            [name]: value,
        });
    };

    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/3877/feedback',
        data: contactDetails,
        submitClick: formSubmitted
    });

    const handleSubmit = async event => {
        event.preventDefault();

        // add the selected class/hull button selections
        setContactDetails({
            ...contactDetails,
        });

        if (contactDetails.newsletter) {
            addToMailchimp(contactDetails.email, { 'PAGEID': 'Contact' });
        }

        // submit the form
        await setFormSubmitted(true);

        // revert the form submitted to default state
        await setFormSubmitted(false);
    };

    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setSelectedCountry(loadLocation);
            setContactDetails({
                ...defaultContactDetails,
                ['country']: loadLocation,
            });
        }
    }, [formSubmitted, formSubmission]);

    useEffect(() => {
        setSelectedCountry(loadLocation);
        setContactDetails({
            ...contactDetails,
            ['country']: loadLocation,
        });
    }, [loadLocation]);

    return (
        <div className="c-form c-contact-form u-bg--wild-sand xs-mt-30 xs-p-30 md-p-50">

            <form className="c-form" onSubmit={handleSubmit}>

                <div className="row">

                    <div className="col-sm-12">
                        <div className="c-input-holder">
                            <input
                                className={contactDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                type="text"
                                id="contactName"
                                name="contactName"
                                value={contactDetails.contactName}
                                onChange={handleInputChange}
                            />
                            <label className="c-input-holder__label" htmlFor="contactName">Name <span>(optional)</span></label>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="c-input-holder">
                            <input
                                className={contactDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                type="text"
                                id="contactEmail"
                                name="email"
                                value={contactDetails.email}
                                onChange={handleInputChange}
                                required
                            />
                            <label className="c-input-holder__label" htmlFor="contactEmail">Email</label>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="c-input-holder">
                            <input
                                className={contactDetails.phoneNumber.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                type="number"
                                id="contactPhoneNumber"
                                name="phoneNumber"
                                value={contactDetails.phoneNumber}
                                onChange={handleInputChange}
                                required
                            />
                            <label className="c-input-holder__label" htmlFor="contactPhoneNumber">Phone number</label>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="c-input-holder">
                            <input
                                className={contactDetails.club.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                type="text"
                                id="club"
                                name="club"
                                value={contactDetails.club}
                                onChange={handleInputChange}
                            />
                            <label className="c-input-holder__label" htmlFor="club">Club name <span>(optional)</span></label>
                        </div>                    
                    </div>

                    <div className="col-sm-12">
                        <div className="c-input-holder">
                            <div className="c-input-holder__wrapper">
                                <select
                                    className="c-input-holder__select"
                                    id="contactCountry"
                                    name="country"
                                    value={selectedCountry}
                                    onChange={handleInputChange}
                                    required="true"
                                >
                                    <option value=''>COUNTRY</option>
                                    {countries.map((country, index) => (
                                        <option key={index} value={country.ACFDealerBlockFields.country}>{country.ACFDealerBlockFields.country}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="c-input-holder">
                            <textarea
                                className={contactDetails.message.length ? "c-input-holder__textarea c-input-holder__textarea--has-content" : "c-input-holder__textarea"}
                                type="text"
                                id="contactMessage"
                                name="message"
                                value={contactDetails.message}
                                onChange={handleInputChange}
                            />
                            <label className="c-input-holder__label" htmlFor="contactMessage">What are you looking to achieve? <span>(optional)</span></label>
                        </div>
                    </div>

                </div>

                <div className="c-contact-form__footer">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="c-input-holder__checkbox-wrapper xs-mb-20 xs-mb-20">
                                <div>
                                    <input
                                        className="c-input-holder__checkbox"
                                        type="checkbox"
                                        name="onlinemeeting"
                                        id="onlinemeeting"
                                        value={contactDetails.onlinemeeting}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <label className="c-input-holder__label--checkbox" htmlFor="onlinemeeting">Want to book an online meeting?</label>
                            </div>
                            <div className="c-input-holder__checkbox-wrapper xs-mb-20 xs-mb-20">
                                <div>
                                    <input
                                        className="c-input-holder__checkbox"
                                        type="checkbox"
                                        name="newsletter"
                                        id="newsletter"
                                        value={contactDetails.newsletter}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <label className="c-input-holder__label--checkbox" htmlFor="newsletter">Tick here to receive the latest product updates and WINTECH racing news straight into your inbox</label>
                            </div>


                            <div className="c-contact-form__submit">
                                <Button text="Send Enquiry" />
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BuildFleetForm;