import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroDistributor from '../components/distributor/component.hero-distributor';
import useFullDealersData from "../hooks/hook.useFullDealersData";
import ComponentParser from '../components/component.parser';
import HeroBanner from '../components/hero-banner/component.banner';
import Content from '../components/component.content';
import LatestSpotlight from '../components/component.latest-spotlight';
import CookieNotice from "../components/component.cookie-notice";
import { withTranslation } from 'react-i18next';
import BuildFleetForm from '../components/contact/component.build-fleet-form';
import Geocode from "react-geocode";
import Axios from "axios";

import { calculateDistance } from "../utilties/helpers";

/**
 * Order the distributors alphabetically by country
 *
 * @param {*} list
 * @returns
 */



const BuildFleet = ({ t, data }) => {
    const dealersData = useFullDealersData();
    const [dealers, setDealers] = useState([]);
    const [reorderDealers, setReorderDealers] = useState(false);
    const [loadLocation, setLoadLocation] = useState([]);
    const [userLocation, setUserLocation] = useState(loadLocation);
    const [changeLocation, setChangeLocation] = useState(false);
    const [latLong, setLatLong] = useState({
        isAvailable: false,
        lat: '',
        long: '',
    });
    const { title, content, blocks, featuredImage, ACFFeaturedVideoBlockFields: video, seo, slug } = data.wordpress.page;

    const bannerImage = featuredImage ? featuredImage.node.imageFile : false;

    function sortAlphabeticallyByCountry(list) {
        const orderedList = list.sort(function(a, b){
            if (a.ACFDealerBlockFields.country < b.ACFDealerBlockFields.country) return -1;
            if (a.ACFDealerBlockFields.country > b.ACFDealerBlockFields.country) return 1;
            return 0;
        });

        return orderedList;
    }

    function orderDistributors() {
        setReorderDealers(true);
        const newDealers = [];
        dealersData.map(dealer => {
            if(dealer.ACFDealerBlockFields.location !== null){
                dealer.distance = calculateDistance({
                    lat1: latLong.lat,
                    lon1: latLong.long,
                    lat2: dealer.ACFDealerBlockFields.location.latitude,
                    lon2: dealer.ACFDealerBlockFields.location.longitude,
                });
                newDealers.push(dealer);
            }
        })
        newDealers.sort((dealerA, dealerB) => (dealerA.distance > dealerB.distance) ? 1 : -1);
        setDealers(newDealers);
    }

    useEffect(() => {

        // check if stored in localStorage:
        let requiresGeoIPLookup = true;
        if (typeof window !== 'undefined' && window) {
            if (localStorage.getItem("storedCountry") !== null) {
                requiresGeoIPLookup = false;
                setUserLocation(localStorage.getItem("storedCountry"));
            }
        }

        if(requiresGeoIPLookup) {
           // https://ipapi.co/api/
            Axios.get("https://ipapi.co/json/")
            .then(result => {
                setUserLocation(result.data.country_name);
            })
            .catch(err => {
                // just use alphabetical then:
                const orderedDealers = sortAlphabeticallyByCountry(dealersData);
                setDealers(orderedDealers);
            })
        } else {
            setDealers(dealersData);
        }

    }, []);

    useEffect(() => {
        orderDistributors();
    }, [latLong]);

    useEffect(() => {
        if (typeof window !== 'undefined' && window) {
            localStorage.setItem('storedCountry',userLocation);
        }
        findLocation();
    }, [userLocation]);

    useEffect(() => {
        if(dealers.length > 0 && reorderDealers) {
            let dealerCountry = dealers[0].ACFDealerBlockFields.country;
            if(dealers[0].ACFDealerBlockFields.dealerCountry) {
                dealerCountry = dealers[0].ACFDealerBlockFields.dealerCountry;
            }
            setLoadLocation(dealerCountry);
            setReorderDealers(false);
        }
    }, [dealers]);

    const handleLocationInputChange = event => {
        setUserLocation(event.target.value);
    };

    async function requestLocation() {
        const browserLocation = window.navigator && window.navigator.geolocation;
        if (browserLocation) {
            browserLocation.getCurrentPosition(async (position) => {
                await setLatLong({
                    isAvailable: true,
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                });

                // save this to local storage:
                Geocode.setApiKey(process.env.GATSBY_GOOGLE_API_KEY);
                let languageKey = "en";
                if(typeof language !== "undefined") {
                    languageKey = language.code.toLowerCase();
                }
                Geocode.setLanguage(languageKey);
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(response => {
                    // parse the results to find the country name:
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                      for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        if(response.results[0].address_components[i].types[j] == "country") {
                            localStorage.setItem('storedCountry',response.results[0].address_components[i].long_name);
                        }
                      }
                    }
                }).catch((error) => {
                });

            }, (error) => {
                theme.setNotification({ message: "We'll need your location to find a distributor", timestamp: Date() });
            });
        }
    }

    async function findLocation(event) {
        if(event) {
            event.preventDefault();
        }

        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey(process.env.GATSBY_GOOGLE_API_KEY);

        let languageKey = "en";
        if(typeof language !== "undefined") {
            languageKey = language.code.toLowerCase();
        }
        Geocode.setLanguage(languageKey);

        // Get latidude & longitude from address.
        Geocode.fromAddress(userLocation).then(response => {
            const { lat, lng } = response.results[0].geometry.location;
            setLatLong({
                lat: lat,
                long: lng
            });
        }).catch((error) => {
            if(event) {
                theme.setNotification({ message: "Your location could not be found.", timestamp: Date() });
            }
        });
    }
    
    return (
        <Layout>
            <SEO title={title} seo={seo} image={featuredImage} slug={slug} templateClass={'distributor'} />
            <HeroBanner title={title} image={bannerImage} video={video} />

            <div className="container">
                <div class="row">
                    <div class="col-md-6 xs-pt-50">

                        <div className="container">
                            <h1 className="c-distributor__build-fleet-main-title">Get In Touch</h1>
                            <h2 className="c-distributor__build-fleet-title">Location: <span>{loadLocation}</span><button className={changeLocation ? "c-distributor__change-location c-distributor__change-location--active" : "c-distributor__change-location"} onClick={() => setChangeLocation(!changeLocation)}>Change location</button></h2>
                            
                        </div>

                        {changeLocation &&
                            <div className="container">
                                <div className="c-distributor__location-form">
                                    <form className="c-hero-input__search" onSubmit={handleLocationInputChange}>
                                        <input className="c-hero-input__search-input" placeholder="Search by postcode or location" value={userLocation} onChange={handleLocationInputChange} />
                                        <button className="c-hero-input__search-button" aria-label="search" onClick={(event) => findLocation(event)}>
                                            <i className="c-hero-input__search-icon far fa-search"></i>
                                        </button>
                                    </form>

                                    <button className="c-hero-input__additional" onClick={() => requestLocation()}>
                                        <i className="c-hero-input__additional-icon fas fa-location-arrow"></i>
                                        <span className="c-hero-input__additional-desc">Use my current location</span>
                                    </button>
                                </div>
                            </div>
                        }

                        {content &&
                            <Content full content={content} />
                        }
                    </div>
                    <div class="col-md-6">
                        <BuildFleetForm
                            loadLocation={loadLocation}
                        />
                    </div>
                </div>
            </div>

            <div className="xs-pt-30 lg-pt-40">
                <div className="row">
                    {dealers.slice(0,1).map((dealer, index) => {
                        return (
                            <div key={index} className="col-md-12">
                                <HeroDistributor distributor={dealer} banner={featuredImage} dealers={dealers} setDealers={setDealers} loadLocation={loadLocation} setLoadLocation={setLoadLocation} showDealerHeader={false} />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="u-bg--dark-blue xs-pt-50 xs-pb-50">
                <div className="container">
                        {blocks &&
                            <ComponentParser blocks={blocks} />
                        }
                </div>
            </div>

            <LatestSpotlight />
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                content
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                ...PageBlocks
                seo {
                    ...SeoPostFields
                }
            }
        }
    }
`;

export default withTranslation()(BuildFleet);
